import React from 'react'
import { usePlayersContext } from 'contexts'
import { playersData } from 'data'
import { fetchPlayer } from 'hooks'

export default function Suspender({ children }) {
  const { playersDispatch } = usePlayersContext()
  const [error, setError] = React.useState(null)

  //
  // Set status when component is mounted
  //
  const [status, setStatus] = React.useState('')
  React.useEffect(() => {
    setStatus('PENDING')
  }, [])

  //
  // Create promise when component is mounted
  //
  const [promise, setPromise] = React.useState(null)
  React.useEffect(() => {
    const data = Promise.all(
      Object.entries(playersData).map(([key, value]) =>
        fetchPlayer(key, value).then(playersDispatch)
      )
    )

    setPromise(data)
  }, [playersDispatch])

  //
  // Suspender
  //
  const release = promise?.then(handleResolved, handleRejected)
  function handleResolved() {
    setStatus('FULFILLED')
  }
  function handleRejected(error) {
    setError(error)
    setStatus('REJECTED')
  }

  if (status === 'PENDING') throw release
  if (status === 'REJECTED') throw error
  if (status === 'FULFILLED') return children
  return null
}
