export const playersData = {
  8477934: {
    type: 'Skater',
    band: 'Forward 1',
    name: 'Draisaitl, L',
    team: 'EDM',
  },
  8476453: {
    type: 'Skater',
    band: 'Forward 1',
    name: 'Kucherov, N',
    team: 'TB',
  },
  8477492: {
    type: 'Skater',
    band: 'Forward 1',
    name: 'MacKinnon, N',
    team: 'COL',
  },
  8473419: {
    type: 'Skater',
    band: 'Forward 1',
    name: 'Marchand, B',
    team: 'BOS',
  },
  8479318: {
    type: 'Skater',
    band: 'Forward 1',
    name: 'Matthews, A',
    team: 'TOR',
  },
  8478402: {
    type: 'Skater',
    band: 'Forward 1',
    name: 'McDavid, C',
    team: 'EDM',
  },
  8476456: {
    type: 'Skater',
    band: 'Forward 2',
    name: 'Huberdeau, J',
    team: 'FLA',
  },
  8478483: {
    type: 'Skater',
    band: 'Forward 2',
    name: 'Marner, M',
    team: 'TOR',
  },
  8478550: {
    type: 'Skater',
    band: 'Forward 2',
    name: 'Panarin, A',
    team: 'NYR',
  },
  8477956: {
    type: 'Skater',
    band: 'Forward 2',
    name: 'Pastrnak, D',
    team: 'BOS',
  },
  8476460: {
    type: 'Skater',
    band: 'Forward 2',
    name: 'Scheifele, M',
    team: 'WPG',
  },
  8474141: {
    type: 'Skater',
    band: 'Forward 2',
    name: 'Kane, P',
    team: 'CHI',
  },
  8478427: {
    type: 'Skater',
    band: 'Forward 3',
    name: 'Aho, S',
    team: 'CAR',
  },
  8477493: {
    type: 'Skater',
    band: 'Forward 3',
    name: 'Barkov, A',
    team: 'FLA',
  },
  8479337: {
    type: 'Skater',
    band: 'Forward 3',
    name: 'DeBrincat, A',
    team: 'CHI',
  },
  8477404: {
    type: 'Skater',
    band: 'Forward 3',
    name: 'Guentzel, J',
    team: 'PIT',
  },
  8475913: {
    type: 'Skater',
    band: 'Forward 3',
    name: 'Stone, M',
    team: 'VGK',
  },
  8480830: {
    type: 'Skater',
    band: 'Forward 3',
    name: 'Svechnikov, A',
    team: 'CAR',
  },
  8471675: {
    type: 'Skater',
    band: 'Forward 4',
    name: 'Crosby, S',
    team: 'PIT',
  },
  8478398: {
    type: 'Skater',
    band: 'Forward 4',
    name: 'Connor, K',
    team: 'WPG',
  },
  8478864: {
    type: 'Skater',
    band: 'Forward 4',
    name: 'Kaprizov, K',
    team: 'MIN',
  },
  8476468: {
    type: 'Skater',
    band: 'Forward 4',
    name: 'Miller, J',
    team: 'VAN',
  },
  8478420: {
    type: 'Skater',
    band: 'Forward 4',
    name: 'Rantanen, M',
    team: 'COL',
  },
  8476459: {
    type: 'Skater',
    band: 'Forward 4',
    name: 'Zibanejad, M',
    team: 'NYR',
  },
  8477940: {
    type: 'Skater',
    band: 'Forward 5',
    name: 'Ehlers, N',
    team: 'WPG',
  },
  8477942: {
    type: 'Skater',
    band: 'Forward 5',
    name: 'Fiala, K',
    team: 'MIN',
  },
  8471685: {
    type: 'Skater',
    band: 'Forward 5',
    name: 'Kopitar, A',
    team: 'LA',
  },
  8475158: {
    type: 'Skater',
    band: 'Forward 5',
    name: 'O’Reilly, R',
    team: 'STL',
  },
  8474157: {
    type: 'Skater',
    band: 'Forward 5',
    name: 'Pacioretty, M',
    team: 'VGK',
  },
  8478010: {
    type: 'Skater',
    band: 'Forward 5',
    name: 'Point, B',
    team: 'TB',
  },
  8478445: {
    type: 'Skater',
    band: 'Forward 6',
    name: 'Barzal, M',
    team: 'NYI',
  },
  8478403: {
    type: 'Skater',
    band: 'Forward 6',
    name: 'Eichel, J',
    team: 'BUF',
  },
  8476346: {
    type: 'Skater',
    band: 'Forward 6',
    name: 'Gaudreau, J',
    team: 'CGY',
  },
  8471214: {
    type: 'Skater',
    band: 'Forward 6',
    name: 'Ovechkin, A',
    team: 'WAS',
  },
  8474102: {
    type: 'Skater',
    band: 'Forward 6',
    name: 'Perron, D',
    team: 'STL',
  },
  8475166: {
    type: 'Skater',
    band: 'Forward 6',
    name: 'Tavares, J',
    team: 'TOR',
  },
  8473563: {
    type: 'Skater',
    band: 'Forward 7',
    name: 'Backstrom, N',
    team: 'WAS',
  },
  8476461: {
    type: 'Skater',
    band: 'Forward 7',
    name: 'Couturier, S',
    team: 'PHI',
  },
  8480012: {
    type: 'Skater',
    band: 'Forward 7',
    name: 'Pettersson, E',
    team: 'VAN',
  },
  8480027: {
    type: 'Skater',
    band: 'Forward 7',
    name: 'Robertson, J',
    team: 'DAL',
  },
  8476458: {
    type: 'Skater',
    band: 'Forward 7',
    name: 'Strome, R',
    team: 'NYR',
  },
  8471218: {
    type: 'Skater',
    band: 'Forward 7',
    name: 'Wheeler, B',
    team: 'WPG',
  },
  8477444: {
    type: 'Skater',
    band: 'Forward 8',
    name: 'Burakovsky, A',
    team: 'COL',
  },
  8476455: {
    type: 'Skater',
    band: 'Forward 8',
    name: 'Landeskog, G',
    team: 'COL',
  },
  8477496: {
    type: 'Skater',
    band: 'Forward 8',
    name: 'Lindholm, E',
    team: 'CGY',
  },
  8474564: {
    type: 'Skater',
    band: 'Forward 8',
    name: 'Stamkos, S',
    team: 'TB',
  },
  8476882: {
    type: 'Skater',
    band: 'Forward 8',
    name: 'Teravainen, T',
    team: 'CAR',
  },
  8479314: {
    type: 'Skater',
    band: 'Forward 8',
    name: 'Tkachuk, M',
    team: 'CGY',
  },
  8478444: {
    type: 'Skater',
    band: 'Forward 9',
    name: 'Boeser, B',
    team: 'VAN',
  },
  8475169: {
    type: 'Skater',
    band: 'Forward 9',
    name: 'Kane, E',
    team: 'SJ',
  },
  8478439: {
    type: 'Skater',
    band: 'Forward 9',
    name: 'Konecny, T',
    team: 'PHI',
  },
  8475810: {
    type: 'Skater',
    band: 'Forward 9',
    name: 'Rust, B',
    team: 'PIT',
  },
  8475794: {
    type: 'Skater',
    band: 'Forward 9',
    name: 'Seguin, T',
    team: 'DAL',
  },
  8474161: {
    type: 'Skater',
    band: 'Forward 9',
    name: 'Voracek, J',
    team: 'CLS',
  },
  8470638: {
    type: 'Skater',
    band: 'Forward 10',
    name: 'Bergeron, P',
    team: 'BOS',
  },
  8477416: {
    type: 'Skater',
    band: 'Forward 10',
    name: 'Bjorkstrand, O',
    team: 'CLS',
  },
  8473512: {
    type: 'Skater',
    band: 'Forward 10',
    name: 'Giroux, C',
    team: 'PHI',
  },
  8478449: {
    type: 'Skater',
    band: 'Forward 10',
    name: 'Hintz, R',
    team: 'DAL',
  },
  8477933: {
    type: 'Skater',
    band: 'Forward 10',
    name: 'Reinhart, S',
    team: 'FLA',
  },
  8477944: {
    type: 'Skater',
    band: 'Forward 10',
    name: 'Vrana, J',
    team: 'DET',
  },
  8477402: {
    type: 'Skater',
    band: 'Forward 11',
    name: 'Buchnevich, P',
    team: 'STL',
  },
  8478856: {
    type: 'Skater',
    band: 'Forward 11',
    name: 'Garland, C',
    team: 'VAN',
  },
  8471215: {
    type: 'Skater',
    band: 'Forward 11',
    name: 'Malkin, E',
    team: 'PIT',
  },
  8473604: {
    type: 'Skater',
    band: 'Forward 11',
    name: 'Toews, J',
    team: 'CHI',
  },
  8475726: {
    type: 'Skater',
    band: 'Forward 11',
    name: 'Toffoli, T',
    team: 'MON',
  },
  8476389: {
    type: 'Skater',
    band: 'Forward 11',
    name: 'Trocheck, V',
    team: 'CAR',
  },
  8477500: {
    type: 'Skater',
    band: 'Forward 12',
    name: 'Horvat, B',
    team: 'VAN',
  },
  8476539: {
    type: 'Skater',
    band: 'Forward 12',
    name: 'Marchessault, J',
    team: 'VGK',
  },
  8476454: {
    type: 'Skater',
    band: 'Forward 12',
    name: 'Nugent-Hopkins, R',
    team: 'EDM',
  },
  8477939: {
    type: 'Skater',
    band: 'Forward 12',
    name: 'Nylander, W',
    team: 'TOR',
  },
  8480018: {
    type: 'Skater',
    band: 'Forward 12',
    name: 'Suzuki, N',
    team: 'MON',
  },
  8475193: {
    type: 'Skater',
    band: 'Forward 12',
    name: 'Tatar, T',
    team: 'NJ',
  },
  8477935: {
    type: 'Skater',
    band: 'Forward 13',
    name: 'Bennett, S',
    team: 'FLA',
  },
  8476881: {
    type: 'Skater',
    band: 'Forward 13',
    name: 'Hertl, T',
    team: 'SJ',
  },
  8474884: {
    type: 'Skater',
    band: 'Forward 13',
    name: 'Hoffman, M',
    team: 'MON',
  },
  8475744: {
    type: 'Skater',
    band: 'Forward 13',
    name: 'Kuznetsov, E',
    team: 'WAS',
  },
  8477955: {
    type: 'Skater',
    band: 'Forward 13',
    name: 'McCann, J',
    team: 'SEA',
  },
  8475765: {
    type: 'Skater',
    band: 'Forward 13',
    name: 'Tarasenko, V',
    team: 'STL',
  },
  8476448: {
    type: 'Skater',
    band: 'Forward 14',
    name: 'Karlsson, W',
    team: 'VGK',
  },
  8479343: {
    type: 'Skater',
    band: 'Forward 14',
    name: 'Keller, C',
    team: 'ARI',
  },
  8477330: {
    type: 'Skater',
    band: 'Forward 14',
    name: 'Kubalik, D',
    team: 'CHI',
  },
  8480039: {
    type: 'Skater',
    band: 'Forward 14',
    name: 'Necas, M',
    team: 'CAR',
  },
  8477409: {
    type: 'Skater',
    band: 'Forward 14',
    name: 'Verhaeghe, C',
    team: 'FLA',
  },
  8481533: {
    type: 'Skater',
    band: 'Forward 14',
    name: 'Zegras, T',
    team: 'ANA',
  },
  8477479: {
    type: 'Skater',
    band: 'Forward 15',
    name: 'Bertuzzi, T',
    team: 'DET',
  },
  8476887: {
    type: 'Skater',
    band: 'Forward 15',
    name: 'Forsberg, F',
    team: 'NSH',
  },
  8473548: {
    type: 'Skater',
    band: 'Forward 15',
    name: 'Kessel, P',
    team: 'ARI',
  },
  8471698: {
    type: 'Skater',
    band: 'Forward 15',
    name: 'Oshie, T',
    team: 'WAS',
  },
  8478458: {
    type: 'Skater',
    band: 'Forward 15',
    name: 'Roslovic, J',
    team: 'CLS',
  },
  8480801: {
    type: 'Skater',
    band: 'Forward 15',
    name: 'Tkachuk, B',
    team: 'OTT',
  },
  8481559: {
    type: 'Skater',
    band: 'Forward 16',
    name: 'Hughes, J',
    team: 'NJ',
  },
  8479339: {
    type: 'Skater',
    band: 'Forward 16',
    name: 'Laine, P',
    team: 'CLS',
  },
  8477946: {
    type: 'Skater',
    band: 'Forward 16',
    name: 'Larkin, D',
    team: 'DET',
  },
  8475754: {
    type: 'Skater',
    band: 'Forward 16',
    name: 'Nelson, B',
    team: 'NYI',
  },
  8470794: {
    type: 'Skater',
    band: 'Forward 16',
    name: 'Pavelski, J',
    team: 'DAL',
  },
  8475170: {
    type: 'Skater',
    band: 'Forward 16',
    name: 'Schenn, B',
    team: 'STL',
  },
  8474590: {
    type: 'Skater',
    band: 'Defence 1',
    name: 'Carlson, J',
    team: 'WAS',
  },
  8479323: {
    type: 'Skater',
    band: 'Defence 1',
    name: 'Fox, A',
    team: 'NYR',
  },
  8475167: {
    type: 'Skater',
    band: 'Defence 1',
    name: 'Hedman, V',
    team: 'TB',
  },
  8480800: {
    type: 'Skater',
    band: 'Defence 1',
    name: 'Hughes, Q',
    team: 'VAN',
  },
  8474600: {
    type: 'Skater',
    band: 'Defence 1',
    name: 'Josi, R',
    team: 'NSH',
  },
  8480069: {
    type: 'Skater',
    band: 'Defence 1',
    name: 'Makar, C',
    team: 'COL',
  },
  8475197: {
    type: 'Skater',
    band: 'Defence 2',
    name: 'Barrie, T',
    team: 'EDM',
  },
  8478469: {
    type: 'Skater',
    band: 'Defence 2',
    name: 'Chabot, T',
    team: 'OTT',
  },
  8479345: {
    type: 'Skater',
    band: 'Defence 2',
    name: 'Chychrun, J',
    team: 'ARI',
  },
  8476462: {
    type: 'Skater',
    band: 'Defence 2',
    name: 'Hamilton, D',
    team: 'NJ',
  },
  8471724: {
    type: 'Skater',
    band: 'Defence 2',
    name: 'Letang, K',
    team: 'PIT',
  },
  8477447: {
    type: 'Skater',
    band: 'Defence 2',
    name: 'Theodore, S',
    team: 'VGK',
  },
  8480839: {
    type: 'Skater',
    band: 'Defence 3',
    name: 'Dahlin, R',
    team: 'BUF',
  },
  8477950: {
    type: 'Skater',
    band: 'Defence 3',
    name: 'DeAngelo, T',
    team: 'CAR',
  },
  8479398: {
    type: 'Skater',
    band: 'Defence 3',
    name: 'Girard, S',
    team: 'COL',
  },
  8480036: {
    type: 'Skater',
    band: 'Defence 3',
    name: 'Heiskanen, M',
    team: 'DAL',
  },
  8477498: {
    type: 'Skater',
    band: 'Defence 3',
    name: 'Nurse, D',
    team: 'EDM',
  },
  8473507: {
    type: 'Skater',
    band: 'Defence 3',
    name: 'Petry, J',
    team: 'MON',
  },
  8477932: {
    type: 'Skater',
    band: 'Defence 4',
    name: 'Ekblad, A',
    team: 'FLA',
  },
  8479425: {
    type: 'Skater',
    band: 'Defence 4',
    name: 'Hronek, F',
    team: 'DET',
  },
  8477495: {
    type: 'Skater',
    band: 'Defence 4',
    name: 'Jones, S',
    team: 'CHI',
  },
  8476792: {
    type: 'Skater',
    band: 'Defence 4',
    name: 'Krug, T',
    team: 'STL',
  },
  8480145: {
    type: 'Skater',
    band: 'Defence 4',
    name: 'Pionk, N',
    team: 'WPG',
  },
  8476853: {
    type: 'Skater',
    band: 'Defence 4',
    name: 'Rielly, M',
    team: 'TOR',
  },
  8474563: {
    type: 'Skater',
    band: 'Defence 5',
    name: 'Doughty, D',
    team: 'LA',
  },
  8475218: {
    type: 'Skater',
    band: 'Defence 5',
    name: 'Ekholm, M',
    team: 'NSH',
  },
  8475906: {
    type: 'Skater',
    band: 'Defence 5',
    name: 'Klingberg, J',
    team: 'DAL',
  },
  8479325: {
    type: 'Skater',
    band: 'Defence 5',
    name: 'McAvoy, C',
    team: 'BOS',
  },
  8479410: {
    type: 'Skater',
    band: 'Defence 5',
    name: 'Sergachev, M',
    team: 'TB',
  },
  8478460: {
    type: 'Skater',
    band: 'Defence 5',
    name: 'Werenski, Z',
    team: 'CLS',
  },
  8475883: {
    type: 'Goalie',
    band: 'Goalie 1',
    name: 'Andersen, F',
    team: 'CAR',
  },
  8476945: {
    type: 'Goalie',
    band: 'Goalie 1',
    name: 'Hellebuyck, C',
    team: 'WPG',
  },
  8477465: {
    type: 'Goalie',
    band: 'Goalie 1',
    name: 'Jarry, T',
    team: 'PIT',
  },
  8475215: {
    type: 'Goalie',
    band: 'Goalie 1',
    name: 'Lehner, R',
    team: 'VGK',
  },
  8477424: {
    type: 'Goalie',
    band: 'Goalie 1',
    name: 'Saros, J',
    team: 'NSH',
  },
  8476883: {
    type: 'Goalie',
    band: 'Goalie 1',
    name: 'Vasilevskiy, A',
    team: 'TB',
  },
  8476412: {
    type: 'Goalie',
    band: 'Goalie 2',
    name: 'Binnington, J',
    team: 'STL',
  },
  8475683: {
    type: 'Goalie',
    band: 'Goalie 2',
    name: 'Bobrovsky, S',
    team: 'FLA',
  },
  8475789: {
    type: 'Goalie',
    band: 'Goalie 2',
    name: 'Campbell, J',
    team: 'TOR',
  },
  8474593: {
    type: 'Goalie',
    band: 'Goalie 2',
    name: 'Markstrom, J',
    team: 'CGY',
  },
  8475660: {
    type: 'Goalie',
    band: 'Goalie 2',
    name: 'Talbot, C',
    team: 'MIN',
  },
  8469608: {
    type: 'Goalie',
    band: 'Goalie 2',
    name: 'Smith, M',
    team: 'EDM',
  },
  8477967: {
    type: 'Goalie',
    band: 'Goalie 3',
    name: 'Demko, T',
    team: 'VAN',
  },
  8470594: {
    type: 'Goalie',
    band: 'Goalie 3',
    name: 'Fleury, M',
    team: 'CHI',
  },
  8475311: {
    type: 'Goalie',
    band: 'Goalie 3',
    name: 'Kuemper, D',
    team: 'COL',
  },
  8478492: {
    type: 'Goalie',
    band: 'Goalie 3',
    name: 'Samsonov, I',
    team: 'WAS',
  },
  8478048: {
    type: 'Goalie',
    band: 'Goalie 3',
    name: 'Shesterkin, I',
    team: 'NYR',
  },
  8476999: {
    type: 'Goalie',
    band: 'Goalie 3',
    name: 'Ullmark, L',
    team: 'BOS',
  },
}
