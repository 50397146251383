import { Notice } from 'components/types'

export default function Delay() {
  return (
    <Notice type="delay" head="Hold your horses">
      Originally, <em>hold your horses</em> literally meant to pull up on a
      horse one was riding or driving from a wagon in order to make the horse
      halt. Today, <em>hold your horses</em> is often used as an imperative,
      which is a verb that is used as a command or exhortation.
    </Notice>
  )
}
