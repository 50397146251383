import React from 'react'
import { PlayersContext } from 'contexts'
import { ErrorBoundary } from 'components/utils'
import { Delay } from 'components/notices'
import { Main } from "components/views"

//
// Must import default export
//
const Suspender = React.lazy(() => import('components/utils/suspender'))

export default function App() {
  return (
    <PlayersContext>
      <ErrorBoundary>
        <React.Suspense fallback={<Delay />}>
          <Suspender>
            <Main />
          </Suspender>
        </React.Suspense>
      </ErrorBoundary>
    </PlayersContext>
  )
}
