import PropTypes from "prop-types"
import styles from './notice.module.css'

const propTypes = {
  type: PropTypes.oneOf([`error`, `delay`]).isRequired,
  head: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default function Notice({ type, head, children }) {
  const classes = {
    root: [styles.root, styles[type]].filter(Boolean).join(' '),
    plot: styles.plot,
    icon: styles.icon,
    head: styles.head,
    body: styles.body,
  }

  return (
    <div className={classes.root}>
      <div className={classes.plot}>
        <div className={classes.icon} />
        <div className={classes.head}>{head}</div>
        <div className={classes.body}>{children}</div>
      </div>
    </div>
  )
}

Notice.propTypes = propTypes