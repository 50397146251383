import React from 'react'
import PropTypes from 'prop-types'
import { Error } from 'components/notices'

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

//
// [1][2][3]
//
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: '', errorInfo: '' }
  }

  //
  // @param error The error that was thrown
  //
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  //
  // @param error The error that was thrown by a descendent component
  // @param errorInfo The componentStack trace of the component that threw the error
  //
  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    return this.state.hasError ? (
      <Error message={this.state.errorInfo?.componentStack?.toString()} />
    ) : (
      this.props.children
    )
  }
}

ErrorBoundary.propTypes = propTypes

//
// [1]
// [Error boundaries]
// (https://reactjs.org/docs/error-boundaries.html)
//
// View in build
//
// Use `static getDerivedStateFromError()` to render a fallback UI
// Use componentDidCatch() to log error information
//
// Does not catch:
// - event handlers
// - asynchronous code (e.g., `setTimeout` or `requestAnimationFrame` callbacks)
// - server-side rendering
// - errors thrown in the error boundary itself (rather than its children)
//
// Read more:
// - https://blog.openreplay.com/catching-errors-in-react-with-error-boundaries
//

//
// [2]
// [static getDerivedStateFromError()]
// (https://www.geeksforgeeks.org/reactjs-getderivedstatefromerror-method/)
//

//
// [3]
// [componentDidCatch()]
// (https://www.geeksforgeeks.org/reactjs-componentdidcatch-method/)
//
