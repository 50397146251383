import React from 'react'
import { entriesData } from 'data'
import { Header, EntryBoard } from 'components/views'
import styles from './main.module.css'

export default function Main() {
  const [state, setState] = React.useState('')
  React.useEffect(() => {
    setState(
      new Intl.DateTimeFormat('en-CA', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }).format(Date.now())
    )
  }, [])
  
  return (
    <div className={styles.root}>
      <Header>
        <div>
          <b>Bitbuy</b>&emsp;<span>|</span>&emsp;Hockey Pool 2021
        </div>
        <div>Updated: {state}</div>
      </Header>
      <div className={styles.entries}>
        {React.Children.toArray(
          Object.entries(entriesData).map(([key, value]) => (
            <EntryBoard name={key} team={value} />
          ))
        )}
      </div>
    </div>
  )
}
