export const entriesData = {
  Charlie: [
    8479318, 8478550, 8477493, 8478864, 8478010, 8471214, 8473563, 8474564,
    8475794, 8470638, 8471215, 8477939, 8474884, 8480039, 8473548, 8479339,
    8475167, 8475197, 8480839, 8477495, 8474563, 8476883, 8475789, 8478492,
  ],
  Dean: [
    8479318, 8478550, 8477493, 8471675, 8478010, 8471214, 8480012, 8479314,
    8475794, 8473512, 8476389, 8477500, 8475765, 8476448, 8476887, 8479339,
    8475167, 8478469, 8480839, 8477495, 8474563, 8476883, 8475789, 8470594,
  ],
  Derek: [
    8478402, 8478550, 8477493, 8478420, 8478010, 8475166, 8471218, 8476455,
    8475794, 8478449, 8473604, 8476454, 8476881, 8477409, 8471698, 8470794,
    8480069, 8475197, 8479398, 8476853, 8479325, 8476883, 8469608, 8475311,
  ],
  Jennifer: [
    8473419, 8478483, 8477404, 8471675, 8477940, 8471214, 8476458, 8474564,
    8478439, 8470638, 8473604, 8480018, 8477935, 8476448, 8477479, 8475170,
    8479323, 8479345, 8477498, 8479425, 8475218, 8476883, 8469608, 8470594,
  ],
  Koza: [
    8478402, 8478483, 8475913, 8471675, 8475158, 8471214, 8471218, 8479314,
    8475169, 8477933, 8476389, 8477939, 8474884, 8481533, 8476887, 8470794,
    8479323, 8476462, 8477950, 8477495, 8479325, 8475215, 8475789, 8475311,
  ],
  Blair: [
    8477934, 8478483, 8477493, 8471675, 8478010, 8478445, 8473563, 8474564,
    8478439, 8473512, 8471215, 8476539, 8475765, 8479343, 8476887, 8479339,
    8474590, 8477447, 8480036, 8477932, 8478460, 8476883, 8475789, 8475311,
  ],
  Marine: [
    8476453, 8478550, 8478427, 8478420, 8474157, 8475166, 8480012, 8476455,
    8478444, 8470638, 8473604, 8480018, 8475744, 8476448, 8480801, 8477946,
    8481559, 8478469, 8477498, 8477495, 8479325, 8475215, 8475683, 8470594,
  ],
  Dylan: [
    8478402, 8474141, 8477493, 8471675, 8474157, 8471214, 8480012, 8474564,
    8478444, 8470638, 8476389, 8477939, 8476881, 8476448, 8471698, 8479339,
    8480069, 8475197, 8473507, 8477932, 8475906, 8476883, 8476412, 8470594,
  ],
  Jack: [
    8478402, 8478550, 8477493, 8478420, 8474157, 8471214, 8480012, 8476455,
    8475794, 8470638, 8477402, 8477939, 8476881, 8477409, 8476887, 8479339,
    8480069, 8477447, 8473507, 8476792, 8475906, 8476883, 8475789, 8475311,
  ],
  Aamir: [
    8478402, 8478550, 8475913, 8471675, 8471685, 8476346, 8476461, 8477496,
    8475169, 8470638, 8476389, 8477939, 8477955, 8477409, 8471698, 8470794,
    8475167, 8471724, 8477498, 8476853, 8475906, 8476883, 8475789, 8478492,
  ],
  Torstein: [
    8479318, 8477956, 8477404, 8471675, 8474157, 8476346, 8476458, 8474564,
    8475810, 8470638, 8473604, 8476539, 8475765, 8477330, 8476887, 8479339,
    8479323, 8471724, 8477498, 8479425, 8479325, 8476945, 8475660, 8470594,
  ],
  Russ: [
    8478402, 8478550, 8480830, 8478420, 8471685, 8475166, 8471218, 8474564,
    8475810, 8478449, 8478856, 8477939, 8477955, 8477330, 8471698, 8481559,
    8475167, 8477447, 8480036, 8476853, 8474563, 8476883, 8475660, 8475311,
  ],
  Santi: [
    8478402, 8478483, 8477493, 8471675, 8475158, 8471214, 8473563, 8474564,
    8475794, 8470638, 8476389, 8477939, 8476881, 8479343, 8471698, 8470794,
    8475167, 8471724, 8477498, 8476853, 8474563, 8476883, 8475789, 8470594,
  ],
  Charles: [
    8478402, 8478550, 8477493, 8478420, 8478010, 8471214, 8480012, 8476455,
    8474161, 8470638, 8473604, 8476454, 8475744, 8480039, 8476887, 8481559,
    8480069, 8475197, 8477498, 8477932, 8475906, 8476883, 8469608, 8478492,
  ],
  Brennan: [
    8478402, 8478550, 8480830, 8476459, 8471685, 8474102, 8476458, 8476882,
    8475169, 8478449, 8476389, 8476454, 8475765, 8480039, 8480801, 8475170,
    8479323, 8475197, 8477498, 8476792, 8474563, 8475883, 8469608, 8478048,
  ],
}
