import React from 'react'
import { usePlayersContext } from 'contexts'
import { EntryRow } from 'components/views'
import styles from './entry-board.module.css'

export default function EntryBoard({ name, team }) {
  const { playersStore } = usePlayersContext()
  const points = team.reduce(
    (a, b) => a + playersStore[b].stat[0] + playersStore[b].stat[1],
    0
  )

  return (
    <div className={styles.root}>
      <h2>{name}</h2>
      <header>
        <EntryRow name="Name" team="Team" points="Points" />
      </header>
      {React.Children.toArray(
        team.map((i) => (
          <EntryRow
            name={playersStore[i].name}
            team={playersStore[i].team}
            points={playersStore[i].stat[0] + playersStore[i].stat[1]}
          />
        ))
      )}
      <footer>
        <EntryRow name="Total" points={points} />
      </footer>
    </div>
  )
}
