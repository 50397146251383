import styles from "./entry-row.module.css"

export default function EntryRow({ name, team, points }) {
  return (
    <div className={styles.root}>
      <div>
        {name} {team && <span>({team})</span>}
      </div>
      <div>{points}</div>
    </div>
  )
}
