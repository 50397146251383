import PropTypes from 'prop-types'
import { Notice } from 'components/types'

const propTypes = {
  message: PropTypes.string,
}

const defaultProps = {
  message: 'Please try again later',
}

export default function Error({ message = defaultProps.message }) {
  return (
    <Notice type="error" head="Something‘s amiss">
      {message}
    </Notice>
  )
}

Error.propTypes = propTypes
