import React from 'react'

//
// [1]
//
const Context = React.createContext()

//
// [2]
//
function reducer(state, action) {
  return { ...state, [action.id]: action.payload }
}

//
// [3]
//
export function PlayersContext({ children }) {
  const [playersStore, playersDispatch] = React.useReducer(reducer, {})

  return (
    <Context.Provider value={{ playersStore, playersDispatch }}>
      {children}
    </Context.Provider>
  )
}

//
// [4]
//
export function usePlayersContext() {
  return React.useContext(Context)
}

//
// [1]
// [React.createContext]
// (https://reactjs.org/docs/context.html#reactcreatecontext)
//

//
// [2]
// [React.useContext]
// (https://reactjs.org/docs/hooks-reference.html#usecontext)
//
// - Accepts a context object (value returned from `React.createContext`)
// - Returns current context value
// - Current context value is `value` prop of `<Context.Provider>`
// - Triggers rerender with latest `value` when `<Context.Provider>` updates
// - Component calling `React.useContext` always rerenders when context `value` changes
//

//
// [3]
// [React.useReducer]
// (https://reactjs.org/docs/hooks-reference.html#usereducer)
//
// - "… more suited for managing state objects that contain multiple sub-values"
// - preferable for "… complex state logic that involves multiple sub-values"
//

//
// [4]
// [Building Your Own Hooks]
// (https://reactjs.org/docs/hooks-custom.html)
//
// - "… it’s just like a normal function"
//

//
// ---
//

//
// [Application State Management with React]
// (https://kentcdodds.com/blog/application-state-management-with-react)
// (2021-07-21)
//

//
// [How to optimize your context value]
// (https://kentcdodds.com/blog/how-to-optimize-your-context-value)
// (2021-03-07)
//
// When to optimize
// - context value changes frequently
// - context has many consumers
// - measured things and things are slow
//

//
// [How to use React Context effectively]
// (https://kentcdodds.com/blog/how-to-use-react-context-effectively)
// [2021-06-05]
//
