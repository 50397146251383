//
// [1][2][3]
//
export async function fetchPlayer(key, value) {
  const link = `https://statsapi.web.nhl.com/api/v1/people/${key}/stats?stats=statsSingleSeason&season=20212022`

  try {
    const data = await fetch(link)

    // throw new Error('message')
    // OR
    // throw 'message'?
    if (!data.ok) throw new Error(`Error: ${data.status}`)

    const json = await data.json()
    const stat = json.stats[0]?.splits[0]?.stat
    const type = {
      Skater: ['goals', 'assists'],
      Goalie: ['wins', 'shutouts'],
    }
    const statOne = stat?.[type[value.type][0]] ?? 0
    const statTwo = stat?.[type[value.type][1]] ?? 0

    const load = {
      id: key,
      payload: {
        ...value,
        stat: [statOne, statTwo],
      },
    }
    return load
  } catch (e) {
    // do what with error?
    // does error boundary catch it?
    throw e
  }
}

//
// [1]
// [Asynchronous programming]
// (https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Asynchronous/Async_await)
//

//
// [2]
// [try…catch]
// (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/try...catch)
//

//
// [3]
// [Fetch]
// (https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API)
//
// - "The Promise returned from `fetch()` **won’t reject on HTTP error status** …"
// - "… will only reject on network failure …"
// - i.e., user is offline or DNS lookup failure
//

//
// To do:
//
// [AbortController]
// (https://developer.mozilla.org/en-US/docs/Web/API/AbortController)
//

//
// ---
//

//
// [Async/await]
// (https://javascript.info/async-await)
//

//
// [Error handling with Async/Await in JS]
// (https://itnext.io/error-handling-with-async-await-in-js-26c3f20bc06a)
// (2019-04-17)
//
